import React from 'react';
import PatientQueue from './PatientQueue';
import ChartPreviewModal from "./ChartPreview"
import { connect } from 'react-redux';
import { hideToast, showToast } from "../../actions/common";
import {
    redirectToSearch, getPatientDetail,
    loadPatientDetail, isChartOpen, checkOpenAssociatedChart,
    getOrganizationNote
} from '../../actions/admin';

class SearchPatient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            patientData: null,
            lastNote: ''

        }
    }
    componentDidMount() {
        const { router } = this.props;
        if (router.location.pathname.indexOf("search") !== -1) {
            const id = router.location.query.id;
            const showPreview = localStorage.getItem(`showPreview${id}`);
            if (id && id.trim() && showPreview) {
                this.props.getPatientDetail(id)
                    .then(patientData => {
                        this.setState({ patientData, showModal: true });
                        this.props.getOrganizationNote(patientData.provider.organization_id);
                        localStorage.removeItem(`showPreview${id}`);

                    });
            }
        }
    }


    preventAccess = (charts) => {
        let result_array = charts['result'];
        for (let i = 0; i < result_array.length; i++) {
            if (result_array[i].chart_open) {
                return true
            }
        }
    }

    openChart = () => {
        const patient = this.state.patientData;
        window.localStorage.setItem('ccm_queue', 'search');
        window.localStorage.setItem('ccm_queue_user', this.props.activeUser.username);
        if (this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.ce_flag) {
            this.props.checkOpenAssociatedChart(patient.id)
                .then((result) => {
                    const preventAccess = this.preventAccess(result);
                    if (preventAccess) {
                        this.props.showToast({
                            status: 'error',
                            title: 'Denied',
                            message: 'An associated chart is currently open.',
                        });
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            this.props.hideToast();
                        }, 2);

                    } else {
                        this.props.loadPatientDetail(patient);
                    }
                })
                .catch((e) => {
                    if (e.code !== 'session_timeout') {
                        this.props.showToast({
                            status: 'error',
                            title: 'Failure',
                            message: 'Failed to check associated charts.',
                        });
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            this.props.hideToast();
                        }, 2);
                    }

                });
        } else {
            this.props.loadPatientDetail(patient);
        }
    }

    onHide = () => {
        window.close();
        this.setState({ showModal: false });
    }
    render() {
        return (<div>
            <PatientQueue
                showSearch
                rowHeight={150}
                patientDetailColumn
                providerDetailColumn
                timeBilledColumn
                statusColumn
                statusActionsColumn
                reengageIndicator
                queue="search"
                label="Lookup Patient"
                columnHeaderStyle={{ textAlign: 'center' }}
            />
            <ChartPreviewModal show={this.state.showModal}
                activeUser={this.props.activeUser}
                organizationNote={this.props.organizationNote}
                onHide={this.onHide} patient={this.state.patientData} notes={this.state.lastNote}
                openChart={this.openChart} />
        </div>)
    }

}

export default connect(
    (state, ownProps) =>
    ({
        router: state.router,
        activeUser: state.admin.activeUser,
        selectedPatient: state.app.selectedPatient,
        organizationNote: state.levelTwoDashboard.organizationNote,
    }),
    {
        redirectToSearch,
        getPatientDetail,
        hideToast,
        showToast,
        loadPatientDetail,
        isChartOpen,
        checkOpenAssociatedChart,
        getOrganizationNote
    }
)(SearchPatient)
